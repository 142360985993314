import React from "react";
import Router from "next/router";
import PriceTag from "public/landing-hero/price-tag.svg";
import NoMoney from "public/landing-hero/no-money.svg";
import MoneyBag from "public/landing-hero/money-bag.svg";
import QuickLink from "elements/QuickLink";
import Search from "elements/Input/Search";
import Button from "elements/Button";
import constants from "utils/constants";
import { useEventTracker } from "utils/useEventTracker";
import { InvestorContactModal } from "./InvestorLandingModal";
import ButtonV2 from "elements/ButtonV2";

export const ViewPricingSection = ({
	hideInvestorCard = false,
	customThirdLine,
}: {
	hideInvestorCard?: boolean;
	customThirdLine?: string;
}) => {
	const [zipCodeInput, setZipCodeInput] = React.useState("");

	const handlePricingSubmit = () => {
		Router.push("/pricing?zipCode=" + zipCodeInput + "#pricing-calculator");
	};

	const [showCommercialContactModal, setShowCommercialContactModal] =
		React.useState(false);

	const trackEvent = useEventTracker();

	const handleCommercialContactModalClick = () => {
		trackEvent({
			eventName: constants.EVENTS.Commerical_GetStarted_Button,
		});
		setShowCommercialContactModal(true);
	};

	return (
		<div id="landing-view-pricing-section">
			<div id="view-pricing-card">
				<p id="view-pricing-card-title">View pricing in&nbsp;your&nbsp;area</p>
				<div id="pricing-value-props">
					<div className="pricing-value-prop">
						<PriceTag />
						<p>Only pay if you save</p>
					</div>
					<div className="pricing-value-prop">
						<NoMoney />
						<p>No upfront costs</p>
					</div>
					<div className="pricing-value-prop">
						<MoneyBag />
						<p>{!!customThirdLine ? customThirdLine : "Local tax experts"}</p>
					</div>
				</div>
				<div id="pricing-zipcode-search-container">
					<form
						id="pricing-zipcode-search"
						onSubmit={e => {
							e.preventDefault();
							handlePricingSubmit();
						}}>
						<Search
							value={zipCodeInput}
							onChange={e => setZipCodeInput(e.target.value)}
							placeholder="Enter Zip Code"
							autoComplete="off"
							required={false}
							showRightIcon={false}
							numeric
							maxLength={5}
						/>
						<div className="search-separator" />
						<ButtonV2
							type="submit"
							disabled={zipCodeInput.length !== 5}
							onClick={e => handlePricingSubmit()}>
							Check Pricing
						</ButtonV2>
					</form>
				</div>
			</div>
			{!hideInvestorCard && (
				<div
					id="investor-signup-card"
					onClick={handleCommercialContactModalClick}>
					<p id="investor-signup-card-title">Real Estate Investor?</p>
					<p id="investor-signup-card-description">
						Whether you're managing a few properties or a large portfolio, we
						can help.
					</p>
					<QuickLink asButton>Sign Up Your Portfolio</QuickLink>
				</div>
			)}
			{showCommercialContactModal && (
				<InvestorContactModal
					setShowCommercialContactModal={setShowCommercialContactModal}
				/>
			)}
		</div>
	);
};
